<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-29 14:24:52
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-29 15:50:37
-->
<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="门店">
              <a-input v-model="searchData.storeName" placeholder="门店名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="员工">
              <a-input v-model="searchData.managerName" placeholder="员工名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button
          v-if="isDistribution && selectedRows.length > 0 && selectedRows[0].status == 2"
          type="primary"
          @click="toHandler('audio')"
        >
          分配
        </a-button>
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="status" slot-scope="text">
          <span> {{ text | status }} </span>
        </span>
        <span slot="summary" slot-scope="text">
          <span v-if="text == 1">未汇总</span>
          <span v-if="text == 2">已汇总</span>
        </span>
        <span slot="expireDate" slot-scope="text,row">

           <span v-if="row.startingTime != null && row.expireDate">{{row.startingTime}} 至 {{row.expireDate}}</span>
        </span>
      </a-table>
      <!-- 编辑表单 -->
      <DealerPreOrdersNum-edit-modal
        ref="DealerPreOrdersNumEditModal"
        @reload="getData"
      ></DealerPreOrdersNum-edit-modal>
      <!-- 审核配额 -->
      <AudioPage ref="AudioPage" @reload="getData"></AudioPage>
    </div>
  </a-card>
</template>

<script>
import { staffColumns } from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import { delDealerPreOrdersNum, employeeSummary } from './api/DealerPreOrdersNumApi'
import AudioPage from './components/AudioPage.vue'
import {checkPermission} from '@/utils/permissions'

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
    AudioPage,
  },
  data() {
    return {
      columns: staffColumns,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      statusList: '',
      isDistribution: checkPermission('summary:list:distribution')
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
          let arr = selectedRows.map((e) => {
            return e.confirmStatus
          })
          this.statusList = arr.every((el) => el == arr[0])
          console.log(11111, this.statusList)
        },
      }
    },
  },

  filters: {
    status(type) {
      const typeMap = {
        0: '草稿',
        1: '待审核',
        2: '已审核待分配',
        3: '审核通过',
        4: '审核拒绝',
      }
      return typeMap[type]
    },
  },

  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      this.selectedRows = []
      this.selectedRowKeys = []
      employeeSummary({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.page = Object.assign({}, this.page)
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    // 汇总
    onSummary() {
      this.axios
        .post(`/api/base/quota/storeStaffApplicationRecord/employeeSummary`, this.selectedRowKeys)
        .then((res) => {
          this.$message.success(res.message)
          this.getData()
        })
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] == record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'staff') {
        _this.$refs.DealerPreOrdersNumEditModal.setRowData({}, 'staff')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'staff':
          _this.$refs.DealerPreOrdersNumEditModal.setRowData(_this.selectedRows[0], 'staff')
          break
        case 'audio':
          _this.$confirm({
            title: '确认分配？',
            onOk: () => {
              _this.axios
                .post(`/api/base/quota/storeStaffApplicationRecord/updateData`, {
                  status: 3,
                  id: this.selectedRows[0].id,
                  storeStaffId: this.selectedRows[0].storeStaffId
                })
                .then((res) => {
                  _this.$message.success(res.message)
                  _this.getData()
                })
                .catch((err) => {})
            },
          })
          break
      }
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
</style>
